/* Useful reference: https://www.smashingmagazine.com/2013/03/tips-and-tricks-for-print-style-sheets/ */

@media print {
    /* Give content more space */
    main {
        margin: 0 !important;
        padding: 0 !important;
    }

    /* Remove elements irrelevant to printed output */
    header,
    footer {
        display: none;
    }

    /*
        Allow content to overflow the page, spanning onto multiple pages as needed, versus being
        cut off at the bottom of the page (viewport dimensions, which are irrelevant to printing)
    */
    #ll-global-scroll-container {
        overflow: visible;
        background: none;
    }

    /* Show our print-specific header */
    #print-header {
        display: flex;
    }

    /* Ensure certain content doesn't break across pages */
    ol,
    ul,
    img {
        break-inside: avoid;
    }

    /* Ensure images don't bleed off the page */
    img {
        max-width: 100% !important;
    }

    /* Prevent orphaned headers e.g. heading at bottom of one page and content starting on the next */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        break-after: avoid;
        break-inside: avoid;
    }
}
